$base-color: #337ab7;
$disable-background-color: #eceeef;
.multiselect-dropdown {
  position: relative;
  width: 100%;
  font-size: inherit;
  font-family: inherit;
  .dropdown-btn {
    display: inline-block;
    border: 1px solid #fff;
    width: 100%;
    padding: 0px 12px;
    margin-bottom: 0;
    font-weight: normal;
    line-height: 1.52857143;
    text-align: left;
    vertical-align: middle;
    cursor: pointer;
    background-image: none;
    border-radius: 4px;
    .selected-item {
      border: 1px solid $base-color;
      margin-right: 4px;
      background: $base-color;
      padding: 0px 5px;
      color: #fff;
      border-radius: 2px;
      float: left;
      a {
        text-decoration: none;
      }
    }
    .selected-item:hover {
      box-shadow: 1px 1px #959595;
    }
    .dropdown-down {
      display: inline-block;
      top: 10px;
      width: 0;
      height: 0;
      border-top: 10px solid #adadad;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
    }
    .dropdown-up {
      display: inline-block;
      width: 0;
      height: 0;
      border-bottom: 10px solid #adadad;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
    }
  }
  .disabled {
    & > span {
      background-color: $disable-background-color;
    }
  }
}

.dropdown-list {
  position: absolute;
  padding-top: 6px;
  width: 100%;
  z-index: 9999;
  border: 1px solid #ccc;
  border-radius: 3px;
  background: #fff;
  margin-top: 10px;
  box-shadow: 0px 1px 5px #959595;
  overflow: hidden;
  ul {
    padding: 0px;
    list-style: none;
    overflow: auto;
    margin: 0px;
  }
  li {
    padding: 6px 10px;
    cursor: pointer;
    text-align: left;
  }
  .filter-textbox {
    border-bottom: 1px solid #ccc;
    position: relative;
    padding: 10px;
    input {
      border: 0px;
      width: 100%;
      padding: 0px 0px 0px 26px;
    }
    input:focus {
      outline: none;
    }
  }
}

.multiselect-item-checkbox input[type='checkbox'] {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.multiselect-item-checkbox input[type='checkbox']:focus + div:before,
.multiselect-item-checkbox input[type='checkbox']:hover + div:before {
  border-color: $base-color;
  background-color: #f2f2f2;
}

.multiselect-item-checkbox input[type='checkbox']:active + div:before {
  transition-duration: 0s;
}

.multiselect-item-checkbox input[type='checkbox'] + div {
  position: relative;
  padding-left: 2em;
  vertical-align: middle;
  user-select: none;
  cursor: pointer;
  margin: 0px;
  color: #000;
}

.multiselect-item-checkbox input[type='checkbox'] + div:before {
  box-sizing: content-box;
  content: '';
  color: $base-color;
  position: absolute;
  top: 50%;
  left: 0;
  width: 14px;
  height: 14px;
  margin-top: -9px;
  border: 2px solid $base-color;
  text-align: center;
  transition: all 0.4s ease;
}

.multiselect-item-checkbox input[type='checkbox'] + div:after {
  box-sizing: content-box;
  content: '';
  position: absolute;
  top: 50%;
  left: 4px;
  transform-origin: 50%;
  transition: transform 200ms ease-out;
  border-style: solid;
  border-color: #ffffff;
  border-width: 0 0 3px 3px;
  border-image: none;
  transform: rotate(-45deg) scale(0);
  background-color: transparent;
  width: 8px;
  height: 3px;
  margin-top: -4px;
}

.multiselect-item-checkbox input[type='checkbox']:disabled + div:before {
  border-color: #cccccc;
}

.multiselect-item-checkbox
  input[type='checkbox']:disabled:focus
  + div:before
  .multiselect-item-checkbox
  input[type='checkbox']:disabled:hover
  + div:before {
  background-color: inherit;
}

.multiselect-item-checkbox
  input[type='checkbox']:disabled:checked
  + div:before {
  background-color: #cccccc;
}

.multiselect-item-checkbox input[type='checkbox']:checked + div:after {
  content: '';
  transform: rotate(-45deg) scale(1);
  transition: transform 200ms ease-out;
}

.multiselect-item-checkbox input[type='checkbox']:checked + div:before {
  animation: borderscale 200ms ease-in;
  background: $base-color;
}

@keyframes borderscale {
  50% {
    box-shadow: 0 0 0 2px $base-color;
  }
}