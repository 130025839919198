/* You can add global styles to this file, and also import other style files */
@import "~bootstrap/dist/css/bootstrap.css";
@import url('https://fonts.googleapis.com/css?family=Ubuntu&display=swap');

label.required:after {
    content: " *";
    color: red;
}

/* Font Family */
html,
body {
    font-family: 'Ubuntu', sans-serif;
    height: 100%;
}

.multiselect-dropdown .dropdown-btn .selected-item {
    margin-bottom: 2px;
}

.multiselect-dropdown .dropdown-btn .selected-item a {
    float: right;
    font-weight: bold;
}
.ngx-slider .ngx-slider-pointer{
    background-color: #dc3545 !important;
}
.ngx-slider .ngx-slider-selection{
    background-color: #dc3545 !important;
}
 th.mat-header-cell {
    text-align: left;
    font-weight: bold;
    font-size: 14px;
    background: #dc3545;
    color:white
} 
.mat-sort-header-arrow {
    color:white
}


body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }


.ngx-toastr {
    @media (max-width: 280px) {
      width: 250px !important; 
    }
  }
  .theme-green .bs-datepicker-head {
    background-color: red;
}
/* .fas{
cursor: pointer;
}  */
